import React from "react";

export default function MobileChatSVG(props: any) {
  return (
    <svg width="229"
         height="148"
         viewBox="0 0 229 148"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
         {...props}>
        <path d="M216.35 64.7002H133.09C129.853 64.7002 126.748 65.9832 124.456 68.2681C122.164 70.5531 120.87 73.6535 120.86 76.8902V121.95C120.87 125.182 122.159 128.278 124.445 130.562C126.731 132.846 129.828 134.132 133.06 134.14H141.49L138.24 147.41C143 146.976 147.621 145.577 151.823 143.299C156.025 141.021 159.719 137.912 162.68 134.16H216.35C219.581 134.152 222.678 132.866 224.964 130.582C227.25 128.298 228.539 125.202 228.55 121.97V76.8902C228.539 73.6587 227.25 70.5628 224.964 68.2787C222.678 65.9946 219.581 64.7081 216.35 64.7002Z" fill="#2390F0"/>
        <path d="M216.35 64.7002H133.09C129.853 64.7002 126.748 65.9832 124.456 68.2681C122.164 70.5531 120.87 73.6535 120.86 76.8902V121.95C120.87 125.182 122.159 128.278 124.445 130.562C126.731 132.846 129.828 134.132 133.06 134.14H141.49L138.24 147.41C143 146.976 147.621 145.577 151.823 143.299C156.025 141.021 159.719 137.912 162.68 134.16H216.35C219.581 134.152 222.678 132.866 224.964 130.582C227.25 128.298 228.539 125.202 228.55 121.97V76.8902C228.539 73.6587 227.25 70.5628 224.964 68.2787C222.678 65.9946 219.581 64.7081 216.35 64.7002Z" fill="#FEC72A"/>
        <path d="M206.54 109.72C212.229 109.72 216.84 105.109 216.84 99.4201C216.84 93.7316 212.229 89.1201 206.54 89.1201C200.852 89.1201 196.24 93.7316 196.24 99.4201C196.24 105.109 200.852 109.72 206.54 109.72Z" fill="#2390F0"/>
        <path d="M174.72 109.72C180.408 109.72 185.02 105.109 185.02 99.4201C185.02 93.7316 180.408 89.1201 174.72 89.1201C169.031 89.1201 164.42 93.7316 164.42 99.4201C164.42 105.109 169.031 109.72 174.72 109.72Z" fill="#2390F0"/>
        <path d="M142.91 109.72C148.598 109.72 153.21 105.109 153.21 99.4201C153.21 93.7316 148.598 89.1201 142.91 89.1201C137.221 89.1201 132.61 93.7316 132.61 99.4201C132.61 105.109 137.221 109.72 142.91 109.72Z" fill="#2390F0"/>
        <path d="M206.54 109.72C212.228 109.72 216.84 105.109 216.84 99.4201C216.84 93.7316 212.228 89.1201 206.54 89.1201C200.851 89.1201 196.24 93.7316 196.24 99.4201C196.24 105.109 200.851 109.72 206.54 109.72Z" fill="#FAFAFA"/>
        <path d="M174.72 109.72C180.408 109.72 185.02 105.109 185.02 99.4201C185.02 93.7316 180.408 89.1201 174.72 89.1201C169.031 89.1201 164.42 93.7316 164.42 99.4201C164.42 105.109 169.031 109.72 174.72 109.72Z" fill="#FAFAFA"/>
        <path d="M142.91 109.72C148.598 109.72 153.21 105.109 153.21 99.4201C153.21 93.7316 148.598 89.1201 142.91 89.1201C137.221 89.1201 132.61 93.7316 132.61 99.4201C132.61 105.109 137.221 109.72 142.91 109.72Z" fill="#FAFAFA"/>
        <path d="M12.19 0H95.46C98.6924 0.0105462 101.789 1.29929 104.075 3.58495C106.361 5.87062 107.649 8.96761 107.66 12.2V57.25C107.639 60.4755 106.346 63.5622 104.061 65.8392C101.776 68.1163 98.6855 69.3995 95.46 69.41H87.03L90.31 82.65C85.5496 82.2173 80.9269 80.8203 76.7236 78.5441C72.5202 76.2679 68.824 73.1602 65.86 69.41H12.19C8.95853 69.3994 5.86258 68.1104 3.57851 65.8244C1.29444 63.5385 0.00790954 60.4415 0 57.21V12.21C0.00527008 8.97679 1.29063 5.87721 3.57498 3.58911C5.85934 1.30101 8.9568 0.0105704 12.19 0Z" fill="#2390F0"/>
        <path d="M12.19 0H95.46C98.6924 0.0105462 101.789 1.29929 104.075 3.58495C106.361 5.87062 107.649 8.96761 107.66 12.2V57.25C107.639 60.4755 106.346 63.5622 104.061 65.8392C101.776 68.1163 98.6855 69.3995 95.46 69.41H87.03L90.31 82.65C85.5496 82.2173 80.9269 80.8203 76.7236 78.5441C72.5202 76.2679 68.824 73.1602 65.86 69.41H12.19C8.95853 69.3994 5.86258 68.1104 3.57851 65.8244C1.29444 63.5385 0.00790954 60.4415 0 57.21V12.21C0.00527008 8.97679 1.29063 5.87721 3.57498 3.58911C5.85934 1.30101 8.9568 0.0105704 12.19 0Z" fill="#2390F0"/>
        <path d="M92.9102 15.6904H14.7402V23.0904H92.9102V15.6904Z" fill="#2390F0"/>
        <path d="M75.0302 31.0195H14.7402V38.4195H75.0302V31.0195Z" fill="#2390F0"/>
        <path d="M82.9002 46.3604H14.7402V53.7604H82.9002V46.3604Z" fill="#2390F0"/>
        <path d="M92.9102 15.6904H14.7402V23.0904H92.9102V15.6904Z" fill="white"/>
        <path d="M75.0302 31.0205H14.7402V38.4205H75.0302V31.0205Z" fill="white"/>
        <path d="M82.9002 46.3604H14.7402V53.7604H82.9002V46.3604Z" fill="white"/>
    </svg>
  );
}

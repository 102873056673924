import {Box, Link, Typography, useMediaQuery, useTheme} from "@mui/material";
import React from "react";
import {SxProps} from "@mui/system";
import {
  Link as RouterLink
} from "react-router-dom"

type Props = {
}
export default function Footer({ }: Props) {
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Box style={styles.footerBox}>

      <Box style={styles.separator}/>

      <Box sx={sxStyles.footerContentBox}>
        <Box style={{ flex: .6 }}>
          <Typography sx={sxStyles.footerContentTitle}>
            About KidsLog
          </Typography>
          <Typography sx={sxStyles.footerContentText}>
            Your logbook app for recording, monitoring <br/>
            and managing children related activities
          </Typography>
          <Box sx={{textAlign: {xs: 'center', sm: 'left'}}}>
            <a href="https://twitter.com/yourkidslog" target="_blank" rel="noreferrer"
               style={styles.socialLink}>
              <i className="fa fa-twitter" aria-hidden="true"/>
            </a>
            <a href="https://facebook.com/yourkidslog" target="_blank" rel="noreferrer"
               style={styles.socialLink}>
              <i className="fa fa-facebook" aria-hidden="true"/>
            </a>
            <a href="#" target="_blank" rel="noreferrer"
               style={styles.socialLink}>
              <i className="fa fa-instagram" aria-hidden="true"/>
            </a>
          </Box>
        </Box>

        <Box style={{ flex: .2 }}>
          <Typography sx={sxStyles.footerContentTitle}>
            Company
          </Typography>
          <RouterLink to="/about" style={{ textDecoration: 'none', color: '#000' }}
                      onClick={() => window.scrollTo(0, 0)}>
            <Typography sx={sxStyles.footerContentText}>
              About us
            </Typography>
          </RouterLink>
          <RouterLink to="/contact" style={{ textDecoration: 'none', color: '#000' }}
                      onClick={() => window.scrollTo(0, 0)}>
            <Typography sx={sxStyles.footerContentText}>
              Contact
            </Typography>
          </RouterLink>
        </Box>
        <Box style={{ flex: .2 }}>
          <Typography sx={sxStyles.footerContentTitle}>
            App
          </Typography>
          <RouterLink to="/demo-request" style={{ textDecoration: 'none', color: '#000' }}
                      onClick={() => window.scrollTo(0, 0)}>
            <Typography sx={sxStyles.footerContentText}>
              Request a Demo
            </Typography>
          </RouterLink>
          <RouterLink to="/privacy-policy" style={{ textDecoration: 'none', color: '#000' }}
                      onClick={() => window.scrollTo(0, 0)}>
            <Typography sx={sxStyles.footerContentText}>
              Privacy Policy
            </Typography>
          </RouterLink>
        </Box>
      </Box>

      <Box sx={sxStyles.footerDownloadBox}>
        <a href="https://apps.apple.com/us/app/kidslog/id6447705145?itsct=apps_box_badge&amp;itscg=30200"
           target="_blank" rel="noreferrer"
           style={{flex: smUp ? .1 : .4}}>
          <img src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1682035200"
               alt="Download on the App Store"
               style={styles.footerDownloadAppStoreImg}/>
        </a>
        <a href='https://play.google.com/store/apps/details?id=com.softruler.kidslog'
           target="_blank" rel="noreferrer"
           style={{flex: smUp ? .1 : .4}}>
          <img alt='Get it on Google Play'
               src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'
               style={{width: '100%'}}/>
        </a>
      </Box>

      <Typography variant="body2" color="text.secondary" align="center"
                  style={{margin: 10}}>
        {'Copyright © '}
        <Link color="inherit" href="https://softruler.com/" target={"_blank"} rel={"noreferrer noopener"}>
          Soft Ruler
        </Link>{' '}
        {new Date().getFullYear()}.
      </Typography>
    </Box>
  );
}

const sxStyles: { [key: string]: SxProps } = {
  footerContentBox: {
    display: 'flex',
    flexDirection: { xs: 'column', sm: 'row' },
    alignItems: 'center',
  },
  footerContentTitle: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '14.4242px',
    lineHeight: '22px',
    paddingTop: { xs: 3, sm: 0 },
    paddingBottom: 3,
    textAlign: { xs: 'center', sm: 'left' },
  },
  footerContentText: {
    fontFamily: 'Poppins',
    fontWeight: 400,
    paddingBottom: 2,
    fontSize: '12.6212px',
    lineHeight: '20px',
    textAlign: { xs: 'center', sm: 'left' },
  },
  footerDownloadBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 2,
    justifyContent: { xs: 'center', sm: 'left' },
  },
}
const styles: { [key: string]: React.CSSProperties } = {
  separator: {
    width: '100%',
    border: '0.901512px solid #E4E4E7',
    marginTop: '3%',
    marginBottom: '3%',
  },
  footerBox: {
    backgroundColor: '#FAFAFB',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'row',
  },
  footerContentTitle: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '14.4242px',
    lineHeight: '22px',
    paddingBottom: 20,
  },
  socialLink: {
    color: '#000',
    marginRight: 25,
    fontSize: 20
  },
  footerDownloadAppStoreImg: {
    borderRadius: 13,
    width: '100%',
    padding: '9%',
    marginLeft: '-9%'
  }
}
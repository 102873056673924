export interface EntityEvent<Event, EventStatus> {
  entity: Event;
  status: EventStatus;
}

export enum FetchStatus {
  FETCHING = 'FETCHING',
  OK = 'OK',
  ERROR = 'ERROR',
  NOT_FOUND = 'NOT_FOUND',
}
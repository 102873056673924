import {Box, Grid, Typography} from "@mui/material";
import {SxProps} from "@mui/system";
import {Link as RouterLink} from "react-router-dom";
import logo from "../../components/image/logo.svg";
import React, {useEffect} from "react";

export default function AppDownload() {

  // Effects
  useEffect(() => {
    // @ts-ignore
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      // do nothing
    }

    if (/android/i.test(userAgent)) {
      window.location.href = 'https://play.google.com/store/apps/details?id=com.softruler.kidslog';
    }

    // @ts-ignore
    if (/iPad|iPhone|iPod/i.test(userAgent) && !window.MSStream) {
      window.location.href = 'https://apps.apple.com/us/app/kidslog/id6447705145?itsct=apps_box_badge&amp;itscg=30200';
    }
  });

  return (
    <Grid container
          spacing={0}
          sx={sxStyles.container}>
      <Box sx={sxStyles.headerContainer}>
        <RouterLink to="/" style={styles.logoRouterLink}>
          <img src={logo}
               style={{height: '2rem'}}
               alt="logo"/>
          <Typography style={styles.logoText} color="textSecondary" variant="h2" fontWeight="500" sx={{ ml: 1 }}>
            KidsLog
          </Typography>
        </RouterLink>
      </Box>

      <Box sx={sxStyles.downloadBox}>
        <Typography style={styles.downloadDescription}>
          Download KidsLog for iOS or Android
        </Typography>
        <Box style={{height: 70, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
          <a href="https://apps.apple.com/us/app/kidslog/id6447705145?itsct=apps_box_badge&amp;itscg=30200"
             target="_blank" rel="noreferrer"
             style={{display: 'flex', justifyContent: 'center', width: 200, height: '100%' }}>
            <img src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1682035200"
                 alt="Download on the App Store"
                 style={styles.downloadAppStoreImg}/>
          </a>
          <a href='https://play.google.com/store/apps/details?id=com.softruler.kidslog'
             target="_blank" rel="noreferrer"
             style={{display: 'flex', justifyContent: 'center', width: 200, height: '100%'}}>
            <img alt='Get it on Google Play'
                 style={{height: '100%'}}
                 src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/>
          </a>
        </Box>
      </Box>
    </Grid>
  );
}

const sxStyles: { [key: string]: SxProps } = {
  container: {
    height: '100vh',
    flexDirection: 'column'
  },
  headerContainer: {
    width: '100%',
    display: 'flex',
    px: 5,
    height: '70px',
  },
  downloadBox: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}
const styles: { [key: string]: React.CSSProperties } = {
  logoRouterLink: {
    display: 'flex',
    height: '100%',
    flexDirection: 'row',
    textDecoration: 'none',
    alignItems: 'center',
    color: '#000',
  },
  logoText: {
    fontFamily: 'Clash Grotesk',
    color: '#2B353F',
    marginLeft: 10,
  },
  downloadDescription: {
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: 25,
    lineHeight: '128.91%',
    paddingLeft: 50,
    paddingRight: 50,
    paddingBottom: 70,
    textAlign: 'center',
  },
  downloadAppStoreImg: {
    borderRadius: 13,
    height: '100%',
    padding: 11,
  },
}

type MenuItemType = {
  title?: string,
  subheader?: string,
  icon: string,
  href: string,
  children?: MenuItemType[],
}

const MenuItems: MenuItemType[] = [
  {
    title: 'Request a Demo',
    icon: 'zap',
    href: '/demo-request',
  },
  {
    title: 'Contact us',
    icon: 'message-square',
    href: '/contact',
  },
  {
    title: 'About us',
    icon: 'info',
    href: '/about',
  },
]

export default MenuItems;

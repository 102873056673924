import React, { useEffect, useRef } from "react";

export const CustomerChat = () => {
  const MessengerRef = useRef();
  useEffect(() => {
    // @ts-ignore
    MessengerRef.current.setAttribute('page_id', '115083434835825');
    // @ts-ignore
    MessengerRef.current.setAttribute('attribution', 'biz_inbox');

    // @ts-ignore
    window.fbAsyncInit = function () {
      // @ts-ignore
      window.FB.init({
        xfbml: true,
        version: 'v16.0',
      });
    };
    (function (d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      // @ts-ignore
      js.src = 'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js';
      // @ts-ignore
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  }, []);
  return (
    <>
      <div id="fb-root"/>
      <div
        // @ts-ignore
        ref={MessengerRef}
        id="fb-customer-chat"
        className="fb-customerchat"/>
    </>
  );
};

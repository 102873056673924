import {Box, Button, Dialog, DialogActions, DialogContent, Grid, TextField, Typography} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";
import React, {useState} from "react";
import {SxProps} from "@mui/system";
import logo from "../../components/image/logo.svg";
import {deleteCurrentUser, reAuthenticate$} from "../../state/base/auth/AuthActions";
import {Dispatch} from "redux";
import {useDispatch, useSelector} from "react-redux";
import {deleteProfileAction} from "../../state/base/profile/ProfileActions";
import {currentUserIdSelector} from "../../state/base/auth/AuthSelectors";

export default function AccountDeletion() {
  const dispatch: Dispatch<any> = useDispatch();

  // Selectors
  const userId: string = useSelector(currentUserIdSelector());

  // State
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const [openConfirmationDialog, setOpenConfirmationDialog] = React.useState(false);

  return (
    <Grid container
          spacing={0}
          sx={sxStyles.container}>
      <Box sx={sxStyles.headerContainer}>
        <RouterLink to="/" style={styles.logoRouterLink}>
          <img src={logo}
               style={{height: '2rem'}}
               alt="logo"/>
          <Typography style={styles.logoText} color="textSecondary" variant="h2" fontWeight="500" sx={{ ml: 1 }}>
            KidsLog
          </Typography>
        </RouterLink>
      </Box>

      <Grid item xs={12} sm={12} lg={4}/>
      <Grid item xs={12} sm={12} lg={4}
            sx={sxStyles.content}>
        <Typography fontWeight="700" variant="h2">
          Account deletion
        </Typography>
        <Box display="flex" alignItems="center">
          <Typography color="textSecondary"
                      variant="h6"
                      fontWeight="500"
                      sx={{ mr: 2 }}>
            Please enter your password to allow this action.
          </Typography>
        </Box>
        <TextField label="Password"
                   variant="outlined"
                   sx={{width: '100%', marginTop: 3}}
                   type="password"
                   value={password}
                   onChange={(e) => onPasswordChange(e.target.value)}
                   error={Boolean(passwordError)}
                   helperText={ passwordError} />
        <Button color="secondary"
                variant="contained"
                size="large"
                fullWidth
                onClick={() => onAccountDelete()}
                disabled={password.length < 4}
                sx={{marginTop: 5, padding: 1.5}}>
          Delete my account
        </Button>

        <Dialog open={openConfirmationDialog} onClose={() => setOpenConfirmationDialog(false)} fullWidth>
          <DialogContent>
            <Typography variant="h3" sx={{ mb: 2 }}>
              Confirm deletion?
            </Typography>
            <Typography variant="h4">
              Account and all associated data will be permanently deleted
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenConfirmationDialog(false)}>Cancel</Button>
            <Button
              type="submit"
              color="error"
              variant="contained"
              onClick={() => onDeletionConfirmed()}
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </Grid>
  )

  function onPasswordChange(value: string) {
    setPassword(value);
    if (Boolean(passwordError)) {
      setPasswordError('');
    }
  }

  function onAccountDelete() {
    reAuthenticate$(password)
      .subscribe({
        next: () => setOpenConfirmationDialog(true),
        error: () => setPasswordError('Password incorrect')
      });
  }

  function onDeletionConfirmed() {
    dispatch(
      deleteProfileAction(userId, () => dispatch(
        deleteCurrentUser())
      ));
  }

}

const sxStyles: { [key: string]: SxProps } = {
  container: {
    height: '100vh',
  },
  headerContainer: {
    display: 'flex',
    px: 5,
    position: 'absolute',
    top: '0',
    height: '70px',
  },
  content: {
    alignSelf: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '100%',
    padding: 5
  },
  createAnAccountLink: {
    display: 'block',
    textDecoration: 'none',
    color: 'primary.main',
  },
}
const styles: { [key: string]: React.CSSProperties } = {
  logoRouterLink: {
    display: 'flex',
    height: '100%',
    flexDirection: 'row',
    textDecoration: 'none',
    alignItems: 'center',
    color: '#000',
  },
  logoText: {
    fontFamily: 'Clash Grotesk',
    color: '#2B353F',
    marginLeft: 10,
  },
}
import React, {useEffect} from 'react';
import {Link as RouterLink, Outlet, useLocation, useNavigate} from "react-router-dom";
import logo from "../../components/image/logo.svg";
import {Box, CircularProgress, Grid, Typography} from "@mui/material";
import ChildrenWithBackpacksTalkingSVG from "../../components/image/svg/ChildrenWithBackpacksTalkingSVG";
import {SxProps} from "@mui/system";
import {useSelector} from "react-redux";
import {isUserAuthenticatedSelector} from "../../state/base/auth/AuthSelectors";

export default function Auth() {
  const location = useLocation();
  const navigate = useNavigate();

  // Selectors
  const isUserAuthenticated = useSelector(isUserAuthenticatedSelector());

  // Effects
  useEffect(() => {
    if (isUserAuthenticated) {
      const fromLocation = location.state?.from?.pathname;
      navigate(fromLocation ?? '/');
    }
  }, [isUserAuthenticated, navigate, location]);

  return isUserAuthenticated == null ?
    <Box sx={{height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <CircularProgress />
    </Box>
    :
    <Grid container
          spacing={0}
          sx={sxStyles.container}>
      <Grid item xs={12} sm={12} lg={6}
            sx={{ background: '#ffffff' }}>
        <Box sx={{ position: 'relative' }}>
          <Box display="flex"
               alignItems="center"
               justifyContent="center"
               sx={sxStyles.firstHalfContainer}>
            <ChildrenWithBackpacksTalkingSVG style={styles.firstHalfImage}/>
          </Box>

          <Box sx={sxStyles.headerContainer}>
            <RouterLink to="/" style={styles.logoRouterLink}>
              <img src={logo}
                   style={{height: '2rem'}}
                   alt="logo"/>
              <Typography style={styles.logoText} color="textSecondary" variant="h2" fontWeight="500" sx={{ ml: 1 }}>
                KidsLog
              </Typography>
            </RouterLink>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={8} lg={6} display="flex" alignItems="center">

        <Outlet />

      </Grid>
    </Grid>;
}

const sxStyles: { [key: string]: SxProps } = {
  container: {
    height: '100vh',
    justifyContent: 'center'
  },
  firstHalfContainer: {
    position: 'relative',
    height: { xs: 'auto', lg: '100vh' },
    right: 'auto',
    margin: '0 auto',
  },
  headerContainer: {
    display: 'flex',
    px: 5,
    position: 'absolute',
    top: '0',
    height: '70px',
  }
}
const styles: { [key: string]: React.CSSProperties } = {
  logoRouterLink: {
    display: 'flex',
    height: '100%',
    flexDirection: 'row',
    textDecoration: 'none',
    alignItems: 'center',
    color: '#000',
  },
  logoText: {
    fontFamily: 'Clash Grotesk',
    color: '#2B353F',
    marginLeft: 10,
  },
  firstHalfImage: {
    height: '90%',
    width: '90%',
    maxWidth: '812px',
  }
}
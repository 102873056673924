import React from "react";

export default function ManagementChartsSVG(props: any) {
  return (
    <svg width="260"
         height="153"
         viewBox="0 0 260 153"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
         {...props}>
          <path d="M111 130H29C26.6139 129.997 24.3263 129.048 22.6391 127.361C20.9518 125.674 20.0027 123.386 20 121V32C20.0027 29.6139 20.9518 27.3263 22.6391 25.6391C24.3263 23.9518 26.6139 23.0027 29 23H111C113.386 23.0027 115.674 23.9518 117.361 25.6391C119.048 27.3263 119.997 29.6139 120 32V121C119.997 123.386 119.048 125.674 117.361 127.361C115.674 129.048 113.386 129.997 111 130ZM29 25C27.1441 25.0021 25.3649 25.7402 24.0526 27.0526C22.7402 28.3649 22.0021 30.1441 22 32V121C22.0021 122.856 22.7402 124.635 24.0526 125.947C25.3649 127.26 27.1441 127.998 29 128H111C112.856 127.998 114.635 127.26 115.947 125.947C117.26 124.635 117.998 122.856 118 121V32C117.998 30.1441 117.26 28.3649 115.947 27.0526C114.635 25.7402 112.856 25.0021 111 25H29Z" fill="#B9BCBE"/>
          <path d="M45.5151 60H37.5151C36.3221 59.9987 35.1782 59.5241 34.3346 58.6805C33.491 57.8369 33.0165 56.6931 33.0151 55.5V47.5C33.0165 46.3069 33.491 45.1631 34.3346 44.3195C35.1782 43.4759 36.3221 43.0013 37.5151 43H45.5151C46.7082 43.0013 47.852 43.4759 48.6956 44.3195C49.5393 45.1631 50.0138 46.3069 50.0151 47.5V55.5C50.0138 56.6931 49.5393 57.8369 48.6956 58.6805C47.852 59.5241 46.7082 59.9987 45.5151 60Z" fill="#2390F0"/>
          <path d="M45.5151 85H37.5151C36.3221 84.9987 35.1782 84.5241 34.3346 83.6805C33.491 82.8369 33.0165 81.6931 33.0151 80.5V72.5C33.0165 71.3069 33.491 70.1631 34.3346 69.3195C35.1782 68.4759 36.3221 68.0013 37.5151 68H45.5151C46.7082 68.0013 47.852 68.4759 48.6956 69.3195C49.5393 70.1631 50.0138 71.3069 50.0151 72.5V80.5C50.0138 81.6931 49.5393 82.8369 48.6956 83.6805C47.852 84.5241 46.7082 84.9987 45.5151 85Z" fill="#B9BCBE"/>
          <path d="M103.985 55.1372H66.6357C65.8404 55.1363 65.0778 54.82 64.5154 54.2576C63.953 53.6951 63.6366 52.9326 63.6357 52.1372V50.8633C63.6366 50.0679 63.953 49.3053 64.5154 48.7429C65.0778 48.1805 65.8404 47.8641 66.6357 47.8633H103.985C104.78 47.8641 105.543 48.1805 106.105 48.7429C106.668 49.3053 106.984 50.0679 106.985 50.8633V52.1372C106.984 52.9326 106.668 53.6951 106.105 54.2576C105.543 54.82 104.78 55.1363 103.985 55.1372Z" fill="#2390F0"/>
          <path d="M103.985 80.1372H66.6357C65.8404 80.1363 65.0778 79.82 64.5154 79.2576C63.953 78.6951 63.6366 77.9326 63.6357 77.1372V75.8633C63.6366 75.0679 63.953 74.3053 64.5154 73.7429C65.0778 73.1805 65.8404 72.8641 66.6357 72.8633H103.985C104.78 72.8641 105.543 73.1805 106.105 73.7429C106.668 74.3053 106.984 75.0679 106.985 75.8633V77.1372C106.984 77.9326 106.668 78.6951 106.105 79.2576C105.543 79.82 104.78 80.1363 103.985 80.1372Z" fill="#B9BCBE"/>
          <path d="M45.5151 110H37.5151C36.3221 109.999 35.1782 109.524 34.3346 108.681C33.491 107.837 33.0165 106.693 33.0151 105.5V97.5C33.0165 96.3069 33.491 95.1631 34.3346 94.3195C35.1782 93.4759 36.3221 93.0013 37.5151 93H45.5151C46.7082 93.0013 47.852 93.4759 48.6956 94.3195C49.5393 95.1631 50.0138 96.3069 50.0151 97.5V105.5C50.0138 106.693 49.5393 107.837 48.6956 108.681C47.852 109.524 46.7082 109.999 45.5151 110Z" fill="#EE514D"/>
          <path d="M103.985 105.137H66.6357C65.8404 105.136 65.0778 104.82 64.5154 104.258C63.953 103.695 63.6366 102.933 63.6357 102.137V100.863C63.6366 100.068 63.953 99.3053 64.5154 98.7429C65.0778 98.1805 65.8404 97.8641 66.6357 97.8633H103.985C104.78 97.8641 105.543 98.1805 106.105 98.7429C106.668 99.3053 106.984 100.068 106.985 100.863V102.137C106.984 102.933 106.668 103.695 106.105 104.258C105.543 104.82 104.78 105.136 103.985 105.137Z" fill="#EE514D"/>
          <path d="M230 113H148C145.614 112.997 143.326 112.048 141.639 110.361C139.952 108.674 139.003 106.386 139 104V15C139.003 12.6139 139.952 10.3263 141.639 8.63907C143.326 6.95183 145.614 6.00273 148 6H230C232.386 6.00273 234.674 6.95183 236.361 8.63907C238.048 10.3263 238.997 12.6139 239 15V104C238.997 106.386 238.048 108.674 236.361 110.361C234.674 112.048 232.386 112.997 230 113ZM148 8C146.144 8.00208 144.365 8.74025 143.053 10.0526C141.74 11.3649 141.002 13.1441 141 15V104C141.002 105.856 141.74 107.635 143.053 108.947C144.365 110.26 146.144 110.998 148 111H230C231.856 110.998 233.635 110.26 234.947 108.947C236.26 107.635 236.998 105.856 237 104V15C236.998 13.1441 236.26 11.3649 234.947 10.0526C233.635 8.74025 231.856 8.00208 230 8H148Z" fill="#B9BCBE"/>
          <path d="M165.205 41.4316H157.205C156.012 41.4303 154.868 40.9558 154.024 40.1122C153.18 39.2685 152.706 38.1247 152.705 36.9316V28.9316C152.706 27.7386 153.18 26.5948 154.024 25.7511C154.868 24.9075 156.012 24.433 157.205 24.4316H165.205C166.398 24.433 167.541 24.9075 168.385 25.7511C169.229 26.5948 169.703 27.7386 169.705 28.9316V36.9316C169.703 38.1247 169.229 39.2685 168.385 40.1122C167.541 40.9558 166.398 41.4303 165.205 41.4316Z" fill="#FEC72A"/>
          <path d="M223.675 36.5688H186.326C185.53 36.568 184.768 36.2516 184.205 35.6892C183.643 35.1268 183.327 34.3642 183.326 33.5688V32.2949C183.327 31.4995 183.643 30.737 184.205 30.1746C184.768 29.6121 185.53 29.2958 186.326 29.2949H223.675C224.47 29.2958 225.233 29.6121 225.795 30.1746C226.358 30.737 226.674 31.4995 226.675 32.2949V33.5688C226.674 34.3642 226.358 35.1268 225.795 35.6892C225.233 36.2516 224.47 36.568 223.675 36.5688Z" fill="#FEC72A"/>
          <path d="M223.675 60.5688H154.326C153.53 60.568 152.768 60.2516 152.205 59.6892C151.643 59.1268 151.327 58.3642 151.326 57.5688V56.2949C151.327 55.4995 151.643 54.737 152.205 54.1746C152.768 53.6121 153.53 53.2958 154.326 53.2949H223.675C224.47 53.2958 225.233 53.6121 225.795 54.1746C226.358 54.737 226.674 55.4995 226.675 56.2949V57.5688C226.674 58.3642 226.358 59.1268 225.795 59.6892C225.233 60.2516 224.47 60.568 223.675 60.5688Z" fill="#FEC72A"/>
          <path d="M223.675 77.5688H154.326C153.53 77.568 152.768 77.2516 152.205 76.6892C151.643 76.1268 151.327 75.3642 151.326 74.5688V73.2949C151.327 72.4995 151.643 71.737 152.205 71.1746C152.768 70.6121 153.53 70.2958 154.326 70.2949H223.675C224.47 70.2958 225.233 70.6121 225.795 71.1746C226.358 71.737 226.674 72.4995 226.675 73.2949V74.5688C226.674 75.3642 226.358 76.1268 225.795 76.6892C225.233 77.2516 224.47 77.568 223.675 77.5688Z" fill="#B9BCBE"/>
          <path d="M223.675 94.5688H154.326C153.53 94.568 152.768 94.2516 152.205 93.6892C151.643 93.1268 151.327 92.3642 151.326 91.5688V90.2949C151.327 89.4995 151.643 88.737 152.205 88.1746C152.768 87.6121 153.53 87.2958 154.326 87.2949H223.675C224.47 87.2958 225.233 87.6121 225.795 88.1746C226.358 88.737 226.674 89.4995 226.675 90.2949V91.5688C226.674 92.3642 226.358 93.1268 225.795 93.6892C225.233 94.2516 224.47 94.568 223.675 94.5688Z" fill="#B9BCBE"/>
    </svg>
  );
}

import React from 'react';
import { Box, Typography } from "@mui/material";

export default function About(){
  return (
    <Box style={{
      height: '35%',
      display: 'flex',
      justifyContent: 'center'
    }}>
      <Typography style={styles.placeholderText}>
        about us
      </Typography>
    </Box>
  )
}

const styles: { [key: string]: React.CSSProperties } = {
  placeholderText: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: 20,
    lineHeight: '128.91%',
    textAlign: 'center',
    minHeight: '20vh',
  }
}

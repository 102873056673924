import { createSelectorCreator, lruMemoize } from "reselect";
import { isEqual } from "lodash";

const createDeepEqualSelector = createSelectorCreator(lruMemoize, isEqual)

export const baseAuthSelector = (state: any) => state.baseAuth;

export const currentUserSelector = () =>
  createDeepEqualSelector(baseAuthSelector, (baseAuth) => baseAuth.user);

export const currentUserIdSelector = () =>
  createDeepEqualSelector(baseAuthSelector, (baseAuth) => baseAuth.user?.uid);

export const currentUserEmailSelector = () =>
  createDeepEqualSelector(baseAuthSelector, (baseAuth) => baseAuth.user?.email);

export const isUserAuthenticatedSelector = () =>
  createDeepEqualSelector(baseAuthSelector, (baseAuth) => baseAuth.isAuthenticated);

export const authenticationFailSelector = () =>
  createDeepEqualSelector(baseAuthSelector, (baseAuth) => baseAuth.authenticationFail);

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBTWeaBYccYwDpUci1sWGEtpUZnHao9rnc",
  authDomain: "kids-log-1c1fe.firebaseapp.com",
  projectId: "kids-log-1c1fe",
  storageBucket: "kids-log-1c1fe.appspot.com",
  messagingSenderId: "53882381595",
  appId: "1:53882381595:web:1ad62139486809f3951fbe"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);

// Initialize Firebase Firestore db and get a reference to the service
export const firestoreDb = getFirestore(app);


import {SET_AUTHENTICATED_USER, SET_AUTHENTICATION_FAIL, SET_UNAUTHENTICATED_USER} from './AuthReducers';
import {auth} from "../../../config/Firebase";
import {RootState} from "../../store";
import {reauthenticateWithCredential, signOut, User, EmailAuthProvider, signInWithEmailAndPassword, createUserWithEmailAndPassword} from "firebase/auth";
import {from} from "rxjs";

export function loadUser(): RootState {
  // @ts-ignore
  return (dispatch) => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        dispatch({type: SET_AUTHENTICATED_USER, user});
      } else {
        dispatch({type: SET_UNAUTHENTICATED_USER});
      }
    });
  };
 }

export function emailPasswordSignIn(email: string, password: string) {
  // @ts-ignore
  return (dispatch) =>
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => dispatch({type: SET_AUTHENTICATED_USER, user: userCredential.user}))
      .catch((e) => dispatch({type: SET_AUTHENTICATION_FAIL, fail: 'Incorrect User or Password'}));
}

export function emailPasswordRegister(email: string, password: string) {
  // @ts-ignore
  return (dispatch) =>
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => dispatch({type: SET_AUTHENTICATED_USER, user: userCredential.user}))
      .catch((e) => dispatch({type: SET_AUTHENTICATION_FAIL, fail: e.userInfo.message}));
}

export function logout(onLogout: () => void) {
  // @ts-ignore
  return (dispatch) => {
    signOut(auth)
      .then(() => {
        dispatch({type: 'USER_LOGOUT'});
        onLogout();
      });
  };
}

export function deleteCurrentUser(onError: (error: Error) => void = () => {}) {
  // @ts-ignore
  return (dispatch) => {
    auth
      .currentUser
      ?.delete()
      .then(() => {
        dispatch({type: 'USER_LOGOUT'});
      })
      .catch((err: Error) => {
        onError(err);
        console.log(err);
      });
  };
}

export function reAuthenticate$(password: string) {
  const credential = EmailAuthProvider.credential(
    auth.currentUser?.email as string,
    password)

  return from(reauthenticateWithCredential(auth.currentUser as User, credential));
}

import {AuthUser} from './AuthModel';

export const SET_AUTHENTICATED_USER = 'base/auth/setAuthenticatedUser';
export const SET_UNAUTHENTICATED_USER = 'base/auth/setUnauthenticatedUser';
export const SET_AUTHENTICATION_FAIL = 'base/auth/setAuthenticationFail';

const initialState: AuthUser = {
  user: undefined,
  isAuthenticated: undefined,
  authenticationFail: undefined,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case SET_AUTHENTICATED_USER:
      return {
        ...state,
        user: action.user,
        isAuthenticated: true,
      };
    case SET_UNAUTHENTICATED_USER:
      return {
        ...state,
        isAuthenticated: false,
      };
    case SET_AUTHENTICATION_FAIL:
      return {
        ...state,
        authenticationFail: action.fail,
      };
    default:
      return state;
  }
};

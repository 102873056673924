import React, {useState} from "react";
import {
  Box,
  useTheme, useMediaQuery, experimentalStyled, Container,
} from "@mui/material";
import { Outlet } from "react-router-dom"
import Sidebar from "./Sidebar";
import Header from "./Header";
import {TopbarHeight} from "../../themes/Theme-variable";
import Footer from "./Footer";

const MainWrapper = experimentalStyled('div')(() => ({
  display: 'flex',
  minHeight: '100vh',
  overflow: 'hidden',
  width: '100%',
}));
const PageWrapper = experimentalStyled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',

  backgroundColor: theme.palette.background.default,
  [theme.breakpoints.up('lg')]: {
    paddingTop: TopbarHeight,
  },
  [theme.breakpoints.down('lg')]: {
    paddingTop: '64px',
  },
}));

export default function BasePage() {
  const theme = useTheme();
  const lgUp = useMediaQuery(theme.breakpoints.up('lg'));

  // Selectors

  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [isMobileSidebarOpen, setMobileSidebarOpen] = useState(false);


  return (
    <MainWrapper>
      <Header
        toggleSidebar={() => setSidebarOpen((prevState) => !prevState)}
        toggleMobileSidebar={() => setMobileSidebarOpen((prevState) => !prevState)}/>

      <Sidebar
        isSidebarOpen={isSidebarOpen}
        isMobileSidebarOpen={isMobileSidebarOpen}
        onSidebarClose={() => setMobileSidebarOpen(false)}
      />

      <PageWrapper>
        <Container
          maxWidth={false}
          sx={{
            paddingTop: '20px',
            paddingLeft: isSidebarOpen && lgUp ? '310px!important' : '',
          }}
        >
          <Box sx={{ minHeight: 'calc(100vh - 170px)' }}>
            <Outlet />
          </Box>

          <Footer/>
        </Container>
      </PageWrapper>

    </MainWrapper>
  );
}
import React from "react";

export default function ActivityMonitoringSVG(props: any) {
  return (
    <svg width="139"
         height="141"
         viewBox="0 0 139 141"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
         {...props}>
        <path d="M69.1148 140.671C107.286 140.671 138.23 109.656 138.23 71.3961C138.23 33.1366 107.286 2.12109 69.1148 2.12109C30.9437 2.12109 0 33.1366 0 71.3961C0 109.656 30.9437 140.671 69.1148 140.671Z" fill="#91C7F8"/>
        <path d="M69.1152 129.125C100.924 129.125 126.711 103.279 126.711 71.3962C126.711 39.5132 100.924 13.667 69.1152 13.667C37.306 13.667 11.5195 39.5132 11.5195 71.3962C11.5195 103.279 37.306 129.125 69.1152 129.125Z" fill="#2390F0"/>
        <path d="M64.2206 92.2731C64.1176 92.2731 64.0145 92.2706 63.9113 92.2655C63.0004 92.2215 62.1098 91.9805 61.3006 91.5591C60.4913 91.1377 59.7826 90.5458 59.223 89.824L50.3881 78.4385C49.3593 77.1097 48.8985 75.4261 49.1065 73.7571C49.3146 72.0882 50.1747 70.5701 51.498 69.5359L51.8158 69.2879C53.1417 68.2568 54.8214 67.7949 56.4866 68.0035C58.1518 68.212 59.6664 69.074 60.6984 70.4004C61.1963 71.0422 61.8267 71.5685 62.5464 71.9433C63.2662 72.3182 64.0582 72.5328 64.8683 72.5724C65.6784 72.612 66.4875 72.4756 67.2402 72.1727C67.9929 71.8698 68.6713 71.4074 69.2292 70.8173L87.1686 51.8366C87.7402 51.2317 88.4252 50.7456 89.1843 50.4061C89.9433 50.0666 90.7617 49.8803 91.5927 49.8578C92.4236 49.8353 93.2508 49.9771 94.0271 50.275C94.8033 50.573 95.5135 51.0213 96.1168 51.5944L96.4097 51.8725C97.6283 53.0297 98.3383 54.6247 98.3836 56.3066C98.4289 57.9884 97.8057 59.6195 96.6512 60.8409L68.8141 90.2919C68.2231 90.9179 67.5109 91.4164 66.7211 91.7571C65.9312 92.0977 65.0804 92.2733 64.2206 92.2731Z" fill="white"/>
    </svg>
  );
}

import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Slide,
  TextField,
  Typography
} from "@mui/material";
import { CustomerChat } from "../../config/Facebook";
import { from } from "rxjs";
import { firestoreDb } from "../../config/Firebase";
import { addDoc, collection } from 'firebase/firestore';
import { TransitionProps } from "@mui/material/transitions";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Contact(){

  // State & Refs
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [submitConfirmationOpen, setSubmitConfirmationOpen] = useState(false);


  return <div style={{ minHeight: '85%' }}>
    <Grid style={styles.content}>
      <Typography
        style={{
          fontFamily: 'Poppins',
          fontWeight: 600,
          fontSize: 35,
          lineHeight: '130%'
        }}
      >
        Get in touch
      </Typography>
      <Typography
        sx={{
          marginTop: 2,
          marginBottom: { xs: 3, sm: 5},
        }}
        style={{
          fontFamily: 'Poppins',
          fontWeight: 400,
          fontSize: 22,
          lineHeight: '120%',
          color: '#70767B',
        }}
      >
        We’d love to hear from you. Please fill out this form.
      </Typography>
      <form
        id="contact-form"
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
        onSubmit={(event) => onSubmit(event)}>
          <TextField
            fullWidth
            required
            id="first-name"
            label="First name"
            name="firstName"
            value={firstName}
            style={styles.inputField}
            sx={{width: {xs:'80vw', sm: '60vw', md:'40vw'}}}
            onChange={(event) => setFirstName(event.target.value)}
            margin="normal"/>
          <TextField
            fullWidth
            required
            error ={!!email && !isValidEmail(email)}
            id="email"
            label="Email"
            name="email"
            value={email}
            style={styles.inputField}
            sx={{width: {xs:'80vw', sm: '60vw', md:'40vw'}}}
            onChange={(event) => setEmail(event.target.value)}
            margin="normal"/>
          <TextField
            fullWidth
            required
            id="message"
            label="Message"
            name="message"
            value={message}
            style={styles.inputField}
            sx={{width: {xs:'80vw', sm: '60vw', md:'40vw'}}}
            onChange={(event) => setMessage(event.target.value)}
            margin="normal"
            multiline
            rows={4}/>
          <Button
            sx={{marginY: { xs: 3, sm: 5}}}
            type="submit"
            variant="contained"
            color="primary">
            Submit
          </Button>
      </form>
    </Grid>
    <CustomerChat/>

    <Dialog
      open={submitConfirmationOpen}
      onClose={() => onSubmitConfirmationClose()}
      TransitionComponent={Transition}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
        Thank you for getting in touch!
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          We appreciate you contacting us. One of our colleagues will get back in touch with you soon!
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onSubmitConfirmationClose()}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  </div>


  function onSubmit(event: any) {
    event.preventDefault();
    if (!isValidEmail(email)) {
      return;
    }
    from(addDoc(collection(firestoreDb, "ContactUsMessage"), { firstName, lastName, email, message }))
      .subscribe(res => {
        setSubmitConfirmationOpen(true);
      });
  }

  function onSubmitConfirmationClose() {
    setSubmitConfirmationOpen(false);
    resetForm();
  }

  function resetForm() {
    setFirstName('');
    setLastName('');
    setEmail('');
    setMessage('');
  }

  function isValidEmail(email: string): boolean {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

}

const styles: {[key: string] : React.CSSProperties} = {
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '5%',
  }
};

import {createContext, ReactNode, useContext, useEffect, useState} from "react"
import {
  onAuthStateChanged,
  User
} from 'firebase/auth'
import {auth} from "../../config/Firebase";

export const UserContext = createContext<{
  currentUser: User | null,
}>({
  currentUser: null,
})

export const AuthContextProvider = ({ children }: { children?: ReactNode }) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null)

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user as User)
    })
    return () => {
      unsubscribe()
    }
  }, [])

  return (
    <UserContext.Provider value={{ currentUser }}>
      {children}
    </UserContext.Provider>
  );
}

export const UserAuth = () => {
  return useContext(UserContext)
}
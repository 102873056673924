import React, {useState} from 'react';
import {Box, Button, TextField, Typography} from "@mui/material";
import {NavLink} from "react-router-dom";
import {SxProps} from "@mui/system";
import {Dispatch} from "redux";
import {useDispatch} from "react-redux";

export default function Register() {
  const dispatch: Dispatch<any> = useDispatch();

  // State
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('');

  return (
    <Box sx={sxStyles.container}>
      <Box sx={sxStyles.secondContainer}>

        <Typography fontWeight="700" variant="h2">
          Welcome to KidsLog
        </Typography>
        <Box display="flex" alignItems="center">
          <Typography color="textSecondary"
                      variant="h6"
                      fontWeight="500"
                      sx={{ mr: 1 }}>
            Already have an Account?
          </Typography>
          <Typography component={NavLink}
                      to="/auth/login"
                      fontWeight="500"
                      sx={sxStyles.createAnAccountLink}>
            Sign In
          </Typography>
        </Box>
        <TextField label="Email Address"
                   variant="outlined"
                   sx={{width: '100%', marginTop: 5}}
                   type="email"
                   value={email}
                   onChange={(e) => setEmail(e.target.value)}  />
        <TextField label="Password"
                   variant="outlined"
                   sx={{width: '100%', marginTop: 3}}
                   type="password"
                   value={password}
                   onChange={(e) => setPassword(e.target.value)} />

        <Button color="secondary"
                variant="contained"
                size="large"
                fullWidth
                onClick={(e) => onRegister(e)}
                sx={{marginTop: 5, padding: 1.5}}>
          Sign Up
        </Button>
      </Box>
    </Box>
  );

  function onRegister(e: any) {
    e.preventDefault();
    //dispatch(emailPasswordRegister(email, password));
  }
}

const sxStyles: { [key: string]: SxProps } = {
  container: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  secondContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '50%'
  },
  createAnAccountLink: {
    display: 'block',
    textDecoration: 'none',
    color: 'primary.main',
  },
}


import { currentUserIdSelector } from '../auth/AuthSelectors';
import {mapValues, pick} from 'lodash';
import {createDeepEqualSelector} from "../../StateUtils";

export const baseProfileSelector = (state: any) => state.baseProfile;

export const profileServerSelector = (id: string) =>
  createDeepEqualSelector(baseProfileSelector, (profileState) => id ? profileState.server[id]?.entity : undefined);

export const profileServerByIdsSelector = (ids: string[]) =>
  createDeepEqualSelector(baseProfileSelector, (profileState) =>
    ids ? mapValues(pick(profileState.server, ids) || {}, profileEntity => profileEntity.entity) : undefined);

export const profileCurrentSelector = () =>
  createDeepEqualSelector(baseProfileSelector, currentUserIdSelector(), (profileState, currentUserId) =>
    !currentUserId || !profileState.server[currentUserId]?.entity ? undefined :
      {id: currentUserId, ...profileState.server[currentUserId]?.entity});

export const profileCurrentFetchStatusSelector = () =>
  createDeepEqualSelector(baseProfileSelector, currentUserIdSelector(), (profileState, currentUserId) =>
    profileState.server[currentUserId]?.status);

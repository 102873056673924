import {omit} from 'lodash';
import {Profile} from '../../../domain/common/Profile';
import {EntityEvent, FetchStatus} from '../../../domain/common/event/EntityEvent';

export const PROFILE_SERVER_ADD_PROFILE = 'base/profile/server/addProfile';
export const PROFILE_SERVER_UPDATE_PROFILE_ENTITY = 'base/profile/server/updateProfileEntity';
export const PROFILE_SERVER_REMOVE_PROFILE = 'base/profile/server/removeProfile';

// local

export const PROFILE_LOCAL_ADD_SEARCHED = 'base/profile/local/addSearched';

export const initialState: {
  server: { [id: string]: EntityEvent<Profile, FetchStatus> },
  local: {
    searched: { [searchTerm: string]: string }
  }
} = {
  server: {},
  local: {
    searched: {}
  }
};

export default (state = initialState, action: any) => {
  switch (action.type) {
  case PROFILE_SERVER_ADD_PROFILE:
    return {
      ...state,
      server: {
        ...state.server,
        [action.id]: action.profile
      }
    };
  case PROFILE_SERVER_UPDATE_PROFILE_ENTITY:
    return {
      ...state,
      server: {
        ...state.server,
        [action.id]: {
          ...state.server[action.id],
          entity: {
            ...state.server[action.id].entity,
            ...action.profile
          }
        }
      }
    };
  case PROFILE_SERVER_REMOVE_PROFILE:
    return {
      ...state,
      server: omit(state.server, action.id)
    };

    // local

  case PROFILE_LOCAL_ADD_SEARCHED:
    return {
      ...state,
      local: {
        ...state.local,
        searched: {
          ...state.local.searched,
          [action.searchTerm]: action.profileId
        }
      }
    };
  default:
    return state;
  }
};

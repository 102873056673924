import React from 'react'
import {Navigate, useLocation} from 'react-router-dom'
import {useSelector} from "react-redux";
import {isUserAuthenticatedSelector} from "../state/base/auth/AuthSelectors";
import {Box, CircularProgress} from "@mui/material";

interface Props {
  children: React.JSX.Element
}
const ProtectedRoute = ({ children }: Props) => {
  const location = useLocation();

  // Selectors
  const isUserAuthenticated = useSelector(isUserAuthenticatedSelector());

  return isUserAuthenticated == null
    ? <Box sx={{height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <CircularProgress />
      </Box>
    : isUserAuthenticated
      ? children
      : <Navigate to="/auth/login" state={{ from: location }} />;
}

export default ProtectedRoute
import { useEffect } from 'react';
import {BuildTheme} from "./Theme-variable";

const ThemeSettings = () => {
  const customizer = {
    activeDir: 'ltr',
    activeNavbarBg: '#0b70fb', // This can be any color,
    activeSidebarBg: '#ffffff', // This can be any color
    activeMode: 'light', // This can be light or dark
    activeTheme: 'ORANGE_THEME', // BLUE_THEME, GREEN_THEME, RED_THEME, BLACK_THEME, PURPLE_THEME, INDIGO_THEME, ORANGE_THEME
    SidebarWidth: 240,
  };
  const theme = BuildTheme({
    direction: customizer.activeDir,
    theme: customizer.activeTheme,
  });
  useEffect(() => {
    document.dir = customizer.activeDir;
  }, [customizer]);

  return theme;
};
export default ThemeSettings;

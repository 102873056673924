import React from "react";

export default function TriangleSVG(props: any) {
  return (
    <svg viewBox="0 0 100 100"
      {...props}>
      <polygon points="50 15, 100 100, 0 100" className="triangle" fill={props.color || "#000"}/>
    </svg>
  );
}
